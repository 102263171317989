@use "sass:math";
@import "../../../../../ui/media.scss";
@import "../../../../../ui/typography.scss";

$section-gap: 30px;

.form {
  display: flex;
  flex-direction: column;

  hr {
    padding: 0 0 25px 0;
    border-top: 1px solid var(--color-input-border);
    margin: 10px 0 0 0;
  }
}

.title {
  margin-bottom: math.div($section-gap, 2);
  font-weight: bold;
}

// .storeInformation {
//   padding-bottom: $section-gap;
//   border-bottom: 1px solid var(--color-snow-heavy);
// }

.storeNameRow,
.storePhoneRow {
  display: flex;
  justify-content: space-between;
}

.copyAddressWrapper {
  display: none;

  @include tablet {
    display: flex;
    justify-content: flex-end;
    padding: 28px 0 0 0;

    & > div {
      flex: 1 0 auto;
      max-width: 200px;
      margin: 0 0 0 10px;
      padding: 0 0 0 10px;
      align-self: flex-start;
      line-height: 1.4;
    }
  }
}

.storeTerminals {
  padding: $section-gap 0;
}

.terminalGrid {
  display: grid;
  // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
}

.terminal {
  display: flex;
  gap: 16px;
}

.imageContainer {
  height: 86px;
  width: 86px;
  background-color: var(--color-snow-heavy);
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 26px;

  // because we cant currently target .image class
  & > * {
    background-color: transparent;
  }
}

.terminalInfoContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.textSmall {
  @include text-small;
}

.textSecondary {
  color: var(--color-silver);
  margin-right: 10px;
}

.wrapper {
  margin: 40px 0 0 0;

  &.hide {
    display: none;
  }
}

.acqWarning {
  padding: 0 0 40px 0;
}

.highlight {
  background-color: var(--color-progress-bar-bg);
  border-color: var(--color-progress-border);
  margin: 0 -10px 16px -10px;
  padding: 10px 10px 0 10px;

  @include landscape {
    margin: 0 -26px 16px -26px;
    padding: 14px 26px 0 26px;
  }

  @include tablet {
    margin: 0 -26px 35px -26px;
    padding: 16px 26px 0 26px;
  }
}

.copyButton {
  margin: 0 0 20px 0;
  width: 100%;

  @include tablet {
    margin: 28px 0 0 0;
    width: auto;
  }
}

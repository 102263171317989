@import "../../../ui/media.scss";

.dark-theme {
  .contract-page {
    .contract-requirements {
      color: var(--color-snow-heavy);
    }
  }
}

.contract-ingress,
.contract-claim {
  padding: 60px 0 0 0;
}

.contract-page {
  background-color: var(--color-progress-bar-bg);

  .input {
    margin: 0 0 14px 0;
    display: block;
  }

  .contract-edit {
    min-height: 100vh;
    padding-bottom: 200px;
  }

  .contract-requirements {
    color: var(--color-slate);
    line-height: 1.5;
    padding: 20px 0 0 0;

    ul {
      list-style-type: disc;
      margin: 10px 0 20px 1em;
    }
  }

  .company-address,
  .business-information {
    padding: 20px 0 40px 0;
  }

  .fieldset {
    max-width: $tablet;
    margin: 0 auto;
  }

  .column {
    &.hidden {
      visibility: hidden;
      pointer-events: none;
    }
  }

  .primary-contact-top {
    margin: 0 0 40px 0;

    b {
      font-size: 2.2rem;
    }
  }

  .delete-warning {
    background-color: #f6eaec;
    color: var(--color-red-heavy);
    border: 1px solid var(--color-red-heavy);
    padding: 20px 16px;
    border-radius: 6px;
    margin: 10px 0 20px 0;
  }

  // .company-data {
  //   dl {
  //     display: flex;
  //     flex-wrap: wrap;
  //     margin: 0 0 30px 0;
  //   }

  //   dt,
  //   dd {
  //     margin: 0;
  //   }

  //   dt {
  //     flex: 0 0 200px;
  //   }

  //   dd {
  //     flex: 1 0 calc(100% - 200px);
  //   }
  // }
}

.delete-contract,
.preview-contract,
.send-for-confirmation {
  margin: 0 auto 20px auto;
  max-width: $tablet;
  position: relative;
}

.delete-contract {
  margin: 40px auto;
}

.contract-edit-menu-item {
  position: relative;

  .active-indicator {
    position: absolute;
    left: 28px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    height: 18px;
    width: 4px;
    background-color: var(--color-link);
    border-radius: 4px;
  }
}

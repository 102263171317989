.not-found-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10vh;

  .four-oh-four {
    display: flex;
    gap: 6px;

    > * {
      display: block;
    }
  }

  .text-header {
    font-weight: 800;
    font-size: 4em;
  }
}

@import "../../../ui/media.scss";

.ids {
  img {
    max-width: 200px;
  }
}

.merchant-status {
  padding: 20px 0 0 0;

  @media (min-width: 1280px) {
    display: flex;
    justify-content: space-between;
  }

  .merchant-status-timeline {
    max-width: 650px;
    flex: 1 0 auto;
  }

  .merchant-status-actions {
    border-top: 1px solid var(--color-text);
    padding: 10px 0 20px 0;

    @media (min-width: 1280px) {
      max-width: $landscape;
      padding: 0 0 0 40px;
      margin: 40px 0 0 0;
      border-top: none;
    }
  }

  .reminder-box {
    .status-box {
      min-height: 120px;
    }
  }

  .confirm-box {
    padding: 20px 0;
    margin: 40px 0 0 0;
  }

  .merchant-status-actions {
    .status-boxes {
      min-height: 80px;
    }
  }

  .revoke-box {
    background-color: rgba(246, 234, 236, 1);
    color: var(--color-red-heavy);
    border: 1px solid var(--color-red-heavy);
    padding: 20px 16px;
    border-radius: 6px;
    margin: 80px 0 140px 0;
  }
}

.no-signee-info {
  max-width: 540px;
  margin: 30px 0 0 0;
  text-wrap: balance;

  @include desktop {
    margin: 40px 0 0 50px;
  }
}

@import "../../../../ui/media.scss";
.associate-review {
  padding: 50px 0;
  max-width: $desktop;

  .tablet-columns {
    // & > div {
    //   max-width: calc(50% - 25px);
    // }

    img {
      max-width: 100%;
    }
  }

  .review-box {
    background-color: var(--color-white);
    border-radius: 12px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    padding: 16px 16px 30px 16px;

    @include landscape {
      padding: 25px 25px 40px 25px;
    }
  }

  .question__wrapper {
    height: 100%;
    margin-left: 16px;
  }

  .associate-show-full-picture .overlay {
    width: 80vw;
    left: 10vw;
    margin: 0 0 0 0;
    top: 50px;
  }

  .associate-review-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px;
    gap: 10px;
    margin-bottom: 16px;
    background-color: var(--color-white);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    overflow: hidden;
    flex-wrap: wrap;

    > div {
      width: 100%;
    }

    .mission-text {
      font-size: 24px;
      font-weight: bold;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .associate-review-passport,
    .associate-review-id-card-front,
    .associate-review-id-card-back {
      box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      overflow: hidden;
      display: grid;
      align-items: center;
    }

    .associate-review-extracted-text {
      font-size: 1.4rem;
      padding-left: 10px;
      padding-top: 20px;
      // div {
      //   // position: relative;
      // }
    }

    .associate-review-questions {
      padding: 0px;

      label {
        font-size: 20px;
        font-weight: bold;
      }

      .associate-review-question {
        margin-bottom: 16px;
      }
    }

    .associate-review-right-column {
      .associate-review-yesno {
        display: flex;
        justify-content: right;
      }
      .communication-log {
        td {
          vertical-align: middle;
        }
      }
    }
  }

  .associate-signature {
    margin: 0 0 60px 0;
  }

  // dl {
  //   @include desktop {
  //     display: flex;
  //     flex-wrap: wrap;
  //   }
  // }

  // dt {
  //   font-style: italic;

  //   @include desktop {
  //     flex: 0 0 165px;
  //   }
  // }

  // dd {
  //   line-height: 1.4;
  //   margin: 0 0 14px 0;

  //   font-weight: bold;
  //   @include desktop {
  //     flex: 0 0 auto;
  //     width: 50%;
  //   }
  // }

  // .space-col-between {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   height: 100%;
  // }

  // .associate-review-passport {
  //   overflow: hidden;
  //   border-radius: 6px;
  // }
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
    border-radius: 12px;
  }
  100% {
    transform: translateX(-10px);
    border-radius: 0;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
  }
}

.email-preview {
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  .main-wrapper {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .iframe-wrapper {
    position: relative;
    height: calc(100vh - 100px);
    width: 100%;
  }
  .iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border: 2px solid var(--link);
    border-radius: 5px;
  }

  .translation-wrapper {
    width: 100%;
    max-width: 400px;
    padding: 0 24px;
    max-height: calc(100vh - 55px);
    overflow-y: auto;
    padding-bottom: 45px;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-label,
    .input-messages {
      display: none;
    }
  }

  textarea {
    line-height: 1.5;
  }
}

@import "../../ui/media.scss";

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 4px;
  height: 50px;
  border-radius: 25px;
  background-color: var(--color-white);
  border: 1px solid var(--color-progress-border);
  position: relative;
  margin: 0 0 10px 0;

  @include tablet {
    padding: 0 8px 0 4px;
  }
}

.info {
  flex: 1 0 auto;
  line-height: 1.2;
  min-width: 0;
  max-width: calc(100% - 80px);
  padding: 0 14px;

  @include tablet {
    padding: 0 20px;
  }

  li {
    font-weight: 500;
    @include truncate;
    width: 100%;
    display: block;
    min-width: 0;
  }
}

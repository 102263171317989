@import "../../../../../ui/media.scss";

.timeline .associate-timeline-item {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  // justify-content: center;
  align-items: flex-start;
  font-weight: 400;
  position: relative;

  .associate-item-name {
    font-weight: 500;
    @include truncate;
    width: 100%;
  }

  .relative {
    max-width: 100%;
  }

  &.active {
    .beacon {
      display: block;
    }

    .event-button {
      display: flex;
    }
  }

  .associate-item-type {
    font-style: italic;
  }

  .associate-item-confirmed,
  .associate-item-docs,
  .associate-item-signature {
    position: relative;
    font-style: italic;
  }

  .associate-item-signature-image {
    max-width: 150px;

    & > img {
      width: 100%;
    }
  }
}

@import "../../../../ui/media.scss";

.contract-edit-menu-links {
  padding: 0;

  ul {
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
    border-bottom: 1px solid var(--color-divider);
  }

  .anchor-validity {
    .icon {
      margin: 0;
    }
  }

  li {
    display: block;
  }

  .beacon {
    visibility: hidden;

    &.has-required-information,
    &.has-missing-information {
      visibility: visible;
    }
  }

  .as-link {
    position: relative;
    padding: 0 0 0 34px;
    text-align: left;
    width: 100%;

    .anchor-validity {
      position: absolute;
      top: 1px;
      left: 0;
    }

    .anchor-name {
      @include truncate;
    }
  }
}

.list {
  max-width: 600px;
  margin: 0 auto;

  li {
    position: relative;
    padding: 0 0 5px 34px;
  }
}

.disabled {
  width: 20px;
  height: 20px;
}

.icon {
  position: absolute;
  top: 5px;
  left: 0;
}

.flex {
  display: flex;
  align-items: center;

  & > div {
    flex: 0 0 60px;
    min-width: 60px;

    &:last-child {
      flex: 1 0 auto;
      line-height: 1.4;
    }
  }
}

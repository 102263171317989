.text-preview {
  padding-left: 24px;
  .main-wrapper {
    display: flex;

    & > * {
      max-width: 500px;
    }
  }

  .preview-wrapper {
    margin-top: 52px;
  }

  .translation-wrapper {
    padding: 0 24px;
    width: 100%;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .input-label,
  .input-messages {
    display: none;
  }

  .counter {
    margin-bottom: 16px;
  }

  textarea {
    min-height: 350px;
    line-height: 1.7;
  }

  pre {
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  .variable-icon-button {
    margin-right: 8px;
  }
}

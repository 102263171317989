@import "./media.scss";

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 1.7;
  font-family: "Zilla Slab", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include tablet {
    font-size: 1.8rem;
  }
}

.line-small {
  line-height: 1.5;
}

.line-mini {
  line-height: 1.4;
}

.line-tiny {
  line-height: 1.3;
}

.app {
  p {
    font-size: 1.6rem;
    line-height: 1.7;
    margin: 14px 0;

    @include tablet {
      font-size: 1.8rem;
    }

    &.line-small {
      line-height: 1.5;
    }

    &.line-mini {
      line-height: 1.4;
    }

    &.line-tiny {
      line-height: 1.3;
    }
  }

  .text-mini {
    font-size: 1.2rem;

    @include tablet {
      font-size: 1.4rem;
    }
  }

  .mini {
    font-size: 1.2rem;
    line-height: 1.4;

    @include tablet {
      font-size: 1.4rem;
    }
  }

  .text-small {
    font-size: 1.4rem;

    @include tablet {
      font-size: 1.6rem;
    }
  }

  .tiny {
    font-size: 1.2rem;
    line-height: 1.3;

    @include tablet {
      font-size: 1.4rem;
    }
  }

  .small {
    font-size: 1.4rem;
    line-height: 1.4;

    @include tablet {
      font-size: 1.6rem;
    }
  }

  .text-regular {
    font-size: 1.6rem;

    @include tablet {
      font-size: 1.8rem;
    }
  }

  .text-regular-plus {
    font-size: 1.8rem;

    @include tablet {
      font-size: 2rem;
    }
  }

  .text-large {
    font-size: 2rem;

    @include tablet {
      font-size: 2.4rem;
    }
  }

  .text-passive,
  .text-secondary {
    color: var(--color-text-passive);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    & + p {
      margin: 0 0 14px 0;
    }
  }

  h1 {
    font-size: 3.4rem;
    line-height: 1.2;
    font-weight: 300;
    margin: 0 0 14px 0;
    letter-spacing: -0.05rem;

    @include tablet {
      font-size: 4rem;
    }
  }

  h2 {
    font-size: 3rem;
    line-height: 1.3;
    font-weight: 300;
    margin: 0 0 14px 0;
    letter-spacing: -0.05rem;

    @include tablet {
      font-size: 3.5rem;
    }
  }

  h3 {
    font-size: 2.5rem;
    line-height: 1.5;
    font-weight: 900;
    margin: 0 0 10px 0;

    @include tablet {
      font-size: 3rem;
    }
  }

  h4 {
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 900;
    margin: 0 0 10px 0;

    @include tablet {
      font-size: 2.4rem;
    }
  }

  h5 {
    font-size: 1.8rem;
    line-height: 1.7;
    font-weight: 900;
    margin: 0 0 10px 0;
  }

  h6 {
    font-size: 1.6rem;
    line-height: 1.7;
    font-weight: 900;
    margin: 0 0 10px 0;
  }

  b {
    font-weight: 700;
  }

  strong {
    font-weight: 900;
  }

  blockquote,
  i {
    font-style: italic;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: clip;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  figure,
  blockquote {
    margin: 0;
    line-height: 1.5;
  }

  figcaption {
    cite {
      font-style: normal;
      font-size: 1.4rem;
      color: var(--color-sub-text);

      @include tablet {
        font-size: 1.6rem;
      }
    }
  }

  .text-right {
    text-align: right;
  }
}

@mixin text-small {
  font-size: 1.4rem;

  @include tablet {
    font-size: 1.6rem;
  }
}

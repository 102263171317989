@import "../../../../ui/media.scss";

.company-data-phone {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
  }

  .phone-copy {
    display: flex;
    justify-content: space-between;
    max-width: $landscape;

    @include tablet {
      order: 1;
      max-width: $desktop;
      flex: 0 0 calc(50% - 25px);
      padding: 28px 0 0 0;
    }
  }

  .phone-input {
    @include tablet {
      order: 0;
      flex: 0 0 calc(50% - 25px);
    }
  }
}

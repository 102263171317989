.mcc-admin,
.add-mcc-modal,
.admin-edit-mcc {
  .fieldset-header {
    background-color: #fff;
  }

  .add-mcc-modal {
    padding: 40px 0 20px 0;
  }

  .beacon {
    display: inline-block;
    top: 6px;
    position: relative;
    margin: 0 4px 0 0;
  }
}

.add-mcc-modal {
  .content {
    padding-top: 3.5rem;
  }
}

.mcc-admin {
  tr.error {
    & > td {
      background-color: rgba(246, 234, 236, 1);
      color: var(--color-red-heavy);
    }
  }

  tr.warning {
    & > td {
      background-color: rgba(255, 246, 213, 1);
      color: #a46200;
    }
  }

  td.warning {
    background-color: rgba(255, 246, 213, 1) !important;
    color: #a46200;
  }

  td.error {
    background-color: rgba(246, 234, 236, 1) !important;
    color: var(--color-red-heavy);
  }

  .buttons {
    margin-top: 26px;
  }
}

.status-boxes {
  position: relative;
  min-height: 50px;
  line-height: 1.5;

  .status-box {
    opacity: 0;
    transition: opacity 300ms ease;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  .default-box {
    opacity: 1;
  }

  &.was-successful,
  &.has-required-information {
    .success-box {
      opacity: 1;
    }

    .default-box {
      opacity: 0;
    }
  }

  &.has-error,
  &.has-missing-information {
    .error-box {
      opacity: 1;
    }

    .default-box {
      opacity: 0;
    }
  }

  &.has-partial-information {
    .warning-box {
      opacity: 1;
    }

    .default-box {
      opacity: 0;
    }
  }
}

.status-box.placeholder,
.status-box.relative {
  position: relative;
  min-height: 50px;
}

@import "../../../../../ui/media.scss";

.header {
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-bg);
  background-color: var(--color-bg);
}

// .headerOpen {
//   // border-color: var(--color-snow-heavy);
//   // background-color: var(--color-snow);
//   // border-radius: 10px 10px 0 0;
// }

.title {
  font-size: 1em;
  font-weight: 900;
}

.body {
  margin-bottom: 24px;
  border-radius: 10px;
  border-width: 0px;
  border-style: solid;
  border-color: var(--color-snow-heavy);
}

.bodyOpen {
  background-color: var(--color-snow);
  border-width: 1px;
}

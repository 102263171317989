@import "../../ui/media.scss";
@import "../../ui/typography.scss";

$border-style: 1px solid var(--color-progress-border);

@mixin line-gap {
  margin-bottom: 7px;
  @include landscape {
    margin-bottom: 10px;
  }
}

@mixin row-padding {
  padding: 5px 10px;
  @include landscape {
    padding: 5px 15px;
  }
}

.root {
  height: auto;
  overflow: hidden;

  border-radius: 6px;
  border: $border-style;

  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

////////// HEADER

.outerHeader {
  display: flex;
  align-items: center;
  line-height: normal;
  cursor: pointer;
  background-color: var(--color-snow);

  padding: 8px 10px;
  @include landscape {
    padding: 10px 15px;
  }
}

.iconContainer {
  height: 50px;
  width: 50px;
  border-radius: 6px;
  margin-right: 12px;

  //   background-color: #fff;
  background-color: var(--color-snow-heavy);
  color: var(--color-link);
  fill: var(--color-link);

  display: flex;
  justify-content: center;
  align-items: center;

  @include tablet {
    height: 60px;
    width: 60px;
    margin-right: 20px;
  }
}

.buttonContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.headerLabel {
  @include text-small;
  @include line-gap();
  font-weight: 500;
  text-transform: capitalize;
  color: var(--color-link);

  // background-color: var(--link-alt);
  // padding: 4px;
  // border-radius: 3px;
  // text-align: center;
  // width: 90px;
}

.headerValue {
  font-weight: 700;
}

////////// TABLE

.table {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  line-break: loose;
}

.row {
  flex: 1;
  display: flex;

  border-bottom: $border-style;
  line-height: normal;

  &:first-child {
    border-top: $border-style;
  }
}

.tdLabel {
  flex: 2;
  border-right: $border-style;
  @include row-padding();
}

.tdValue {
  flex: 1;
  @include row-padding();
}

.label {
  @include text-small;
  color: var(--color-text-passive);
  font-weight: 500;
  text-transform: capitalize;
  @include line-gap();
}

.displayValue {
  font-weight: 500;
}

////////// FOOTER

.terms {
  background-color: var(--link-alt);
  border-radius: 10px;
  padding: 15px 15px 15px 35px;
  margin: 15px;
}

.termsTitle {
  color: var(--color-link);
}

.list {
  margin-top: 15px;
  list-style: disc;
  line-height: normal;
  color: var(--color-link);
}

.li {
  margin-bottom: 15px;
  font-weight: 500;
}

.beacon {
  height: 42px;
  width: 42px;
  border-radius: 21px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .icon {
    width: 30px;
    height: 30px;
    display: block;
  }

  &.mini {
    height: 22px;
    width: 22px;
    border-radius: 11px;
    padding: 3px;

    & > .icon {
      width: 16px;
      height: 16px;
    }
  }

  & > .icon {
    fill: var(--color-silver);
  }

  &.has-required-information {
    background-color: rgba(196, 247, 230, 1);
    background-color: #e5f2f4;

    & > .icon {
      fill: #13ae7a;
      fill: var(--color-link);
    }
  }

  &.has-missing-information {
    background-color: rgba(246, 234, 236, 1);

    & > .icon {
      fill: #c04647;
    }
  }

  &.has-partial-information {
    background-color: rgba(255, 246, 213, 1);

    & > .icon {
      fill: #e69900;
    }
  }
}

@import "../../ui/media.scss";

#menu-wrapper-backdrop {
  transition: opacity 300ms ease;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-backdrop);
  background-color: var(--color-black);
}

.menu-wrapper {
  display: flex;

  &.is-open {
    #menu-wrapper-backdrop {
      opacity: 0.6;
      pointer-events: all;
    }
  }

  &.is-padded {
    .menu-wrapper-content {
      padding: 50px 0 0;
    }
  }

  &-sidebar {
    max-width: 50%;

    @include tablet {
      max-width: calc(50% - 135px);
    }
  }

  &-sidebar:empty {
    display: none;
  }

  &-menu {
    @include desktop {
      width: var(--vertical-menu-width-tablet);
      height: 100%;
    }

    @include desktop {
      position: relative;
    }

    > * {
      pointer-events: initial;
    }

    .icon.close {
      width: 16px;
      height: 16px;
    }
  }

  &-button {
    pointer-events: all;
    position: fixed;
    bottom: 40px;
    right: 16px;
    z-index: var(--z-vertical-nav);

    @include landscape {
      bottom: 50px;
      right: 25px;
    }

    @include desktop {
      display: none;
    }
  }

  &-content {
    overflow: auto;
    flex: 1;
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

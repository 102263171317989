.reprovisioning {
  padding: 80px 40px;
}

.wrapper {
  max-width: 660px;
}

.body {
  margin: 100px 0;
}

dd,
dt {
  margin: 0;
  padding: 0;
}

dl {
  display: flex;
  flex-wrap: wrap;

  dt {
    font-weight: bold;
    flex: 0 0 50%;
  }

  dd {
    flex: 0 0 50%;
  }
}

@import "../../../../../ui/media.scss";

.reminder {
  margin: 4px 0 0 0;

  dl {
    margin: 0;
  }

  dt {
    flex: 0 0 30%;
    font-weight: normal;
    font-style: italic;
  }

  dd {
    flex: 0 0 70%;
    @include truncate;
  }
}

.info {
  margin: 0 0 8px 0;
  line-height: 1.2;
  white-space: nowrap;
}

.today {
  padding: 0 5px 2px 5px;
  background-color: black;
  border-radius: 5px;
  color: var(--color-white);
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0;
  }
}

.merchant-status-page {
  background-color: var(--color-progress-bar-bg);

  .menu-wrapper-content {
    padding: 80px 0;
  }

  .input {
    margin: 0 0 14px 0;
    display: block;
  }
}

@import "../../ui/media.scss";

.menu {
  .menu-link {
    display: block;
    height: 40px;
    line-height: 40px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: background-color 300ms ease;
    text-decoration: none;

    .icon {
      margin: -2px 10px 0 0;
    }

    &.is-active {
      background-color: var(--link-alt);
    }

    &.is-disabled {
      color: var(--color-silver);

      .icon {
        fill: var(--color-silver);
      }
    }

    &:hover,
    &:active,
    &:focus {
      background-color: var(--link-alt);
    }
  }
}

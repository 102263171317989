@import "../../../../../../ui/media.scss";

.wrapper {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    align-content: space-between;
    align-items: center;
  }

  & > div {
    @include tablet {
      flex: 0 0 140px;
    }
  }

  & > div:first-child {
    flex: 1 0 auto;
  }

  :global(button) {
    font-size: 2.4rem;
  }
}

.terminalButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include tablet {
    justify-content: space-between;
  }
}

.value {
  font-size: 2.4rem;
  max-width: 60px;
  min-width: 40px;
  font-weight: 500;
  text-align: center;
}

.header {
  background-color: var(--color-text);

  button {
    display: flex;
    color: white;
    text-decoration: underline;
    width: 100%;
    justify-content: flex-start;
    padding: 7px 0 9px 0;

    &:hover,
    &:focus,
    &:active {
      color: white;
    }
  }

  .chevron {
    opacity: 0;
    transform: translate3d(4px, 2px, 0) rotate(-90deg);
    transition: opacity 300ms ease, transform 300ms ease;
  }

  &.activeHeader {
    .chevron {
      opacity: 1;
    }
  }

  &.ascending {
    .chevron {
      transform: translate3d(4px, 2px, 0) rotate(90deg);
    }
  }
}

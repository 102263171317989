.icon-pending {
  position: relative;
  display: inline-block;
  vertical-align: top;

  span {
    display: inline-block;
    position: absolute;
    left: 10px;
    top: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--color-text);
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-delay: 200ms;
    animation-direction: alternate;
    animation-name: bump;

    &:first-child {
      left: 2px;
      animation-delay: 100ms;
    }

    &:last-child {
      left: 18px;
      animation-delay: 300ms;
    }
  }
}

.button,
.as-button {
  .icon-pending {
    position: relative;
    display: inline-block;
    vertical-align: top;

    span {
      background-color: var(--color-white);
    }
  }
}

.ghost {
  .button,
  .as-button {
    span {
      background-color: var(--color-text);
    }
  }
}

@keyframes bump {
  0% {
    transform: translateY(6px);
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: translateY(0px);
    opacity: 0.8;
  }
}

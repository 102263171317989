@import "../ui/media.scss";

.app {
  .page {
    min-height: 100vh;
    padding-top: 10px;

    .accent-bar {
      width: 100%;
      height: 10px;
      position: fixed;
      background-color: #7e00bd;
      z-index: 199;
      top: 0;

      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: -30px;
        height: 30px;
        width: 15px;
        border-top-left-radius: 15px;
        box-shadow: 0 -15px 0 0 #7e00bd;
      }

      &::after {
        right: 0;
        left: unset;
        border-top-left-radius: unset;
        border-top-right-radius: 20px;
      }
    }

    &.background {
      background-color: var(--color-progress-bar-bg);
    }
  }

  .contract-page-menu {
    .nav-link > a {
      justify-content: left;
      display: flex;
    }
    .nav-link .active {
      background-color: var(--color-button);
      box-shadow: var(--color-button-shadow);
      color: var(--color-white);

      .icon {
        fill: var(--color-white);
      }
    }
  }
}

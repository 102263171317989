@import "../../../../ui/media.scss";

.stores {
  .fieldset {
    min-height: 200px;
  }

  .add-button {
    padding-top: 20px;
  }
}

.store {
  border-top: 1px solid var(--color-progress-border);
  padding: 0 0 20px 0;

  &:first-child {
    border-top: 0;
  }

  .remove-button {
    align-self: flex-end;
    margin: 0 0 14px 0;
  }

  .closure-settings {
    align-items: center;

    .icon {
      transition: transform 300ms ease;
      transform: rotate(0);
    }

    &.open {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .terminal-closure-setting {
    padding: 10px 0;
  }

  .commercial-name-wrapper {
    display: none;

    @include tablet {
      display: flex;
      justify-content: flex-end;
      padding: 28px 0 0 0;

      & > div {
        flex: 1 0 auto;
        max-width: 200px;
        margin: 0 0 0 10px;
        padding: 0 0 0 10px;
        align-self: flex-start;
        line-height: 1.4;
      }

      b {
        font-weight: 500;
      }
    }
  }
}

@import "../../ui/media.scss";

.notifier {
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 100;

  @include landscape {
    top: 25px;
    right: 25px;
  }

  .notifier-error,
  .notifier-saved {
    background-color: var(--color-black);
    color: var(--color-white);
    font-weight: 500;
    border-radius: 5px;
    line-height: 1.3;
  }

  .notifier-saved {
    padding: 1px 0 3px 0;
    text-align: center;
    width: 120px;
  }

  .notifier-error {
    background-color: rgba(246, 234, 236, 1);
    color: var(--color-red-heavy);
    border: 1px solid var(--color-red-heavy);
    max-width: 340px;
    margin: 0 0 10px 0;
    padding: 16px;

    .button {
      margin: 20px 0 0 0;
    }
  }
}

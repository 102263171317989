@import "../../../ui/media.scss";

.vertical-menu {
  display: flex;
  flex-direction: column;

  &.vertical-menu-logo,
  & > nav,
  & > a {
    flex: 0 0 auto;
  }

  & > div {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .language-select {
    margin: 0 0 14px 0;
  }
}

@import "../../../ui/media.scss";

.translations-upload {
  margin: 0;
  position: relative;

  .doc-upload-button {
    margin: 10px 0 0 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include tablet {
      margin: 0;
      justify-content: flex-end;
    }

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .progress {
      background-color: var(--color-bg);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 5px;
      border-radius: 5px;
      opacity: 0;
      transition: opacity ease 300ms;
      pointer-events: none;
    }

    &.is-pending {
      .progress {
        opacity: 1;
      }
    }
  }
}

@import "../../../../ui/media.scss";

.timeline {
  margin: 40px 0 0 0;
  position: relative;

  .counter {
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: middle;
  }

  .timeline-sketch {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100%;
  }

  .timeline-tags {
    margin: 0 0 0 35px;

    @include tablet {
      margin: 0 0 0 50px;
    }

    .timeline-item-type {
      font-weight: 300;
      margin: 0 0 0 6px;
      font-size: 0.9em;
    }

    li {
      // height: 85px;
      display: flex;
      // font-size: 1.6rem;

      opacity: 0.5;
      line-height: 1.4;

      &.active {
        opacity: 1;
      }

      b {
        font-weight: 500;
      }

      .additional-event-info {
        position: relative;
        max-width: 280px;
      }
    }

    .timeline-event-document {
      padding: 0 0 0 73px;
      // height: 120px;

      @include landscape {
        padding: 0 0 0 93px;
      }
    }

    .associate-timeline-item {
      padding: 0 0 0 73px;
      // height: 180px;

      @include landscape {
        padding: 0 0 0 93px;
      }
    }
  }

  .associate-timeline-item {
    position: relative;
  }

  .additional-event-passive-text {
    font-style: italic;
  }

  .timeline-date {
    flex: 0 0 auto;
    margin: 3px 8px 0 0;
    font-style: italic;
    width: 70px;
    opacity: 0.7;
    font-weight: 400;
    font-size: 1.4rem;

    @include landscape {
      width: 85px;
    }
  }

  .event {
    display: flex;
    position: relative;
    width: 100%;
  }

  .aligned {
    position: absolute;
    top: 1px;
    left: -30px;
  }

  .event-button,
  a.as-button.event-button {
    font-size: 1.6rem;
    position: absolute;
    right: 0;
    top: 30px;
    z-index: 10;
    min-width: 125px;

    .icon {
      margin: 0 6px 0 -3px;
      width: 20px;
      height: 20px;
    }
  }

  .event-button {
    &.is-disabled {
      .icon-disabled {
        display: block;
        margin: 0 0 0 6px;
      }

      .icon-edit {
        display: none;
      }
    }
  }

  .timeline-event-document {
    position: relative;

    & > div {
      flex: 1 0 auto;
      max-width: 100%;
    }

    .truncated {
      @include truncate;
    }
  }
}

.timeline-sketch {
  @include tablet {
    margin: 0 0 0 10px;
  }

  path,
  line {
    fill: none;
    stroke: var(--color-smoke-heavy);

    &.active {
      stroke: var(--color-link);
    }
  }

  circle {
    stroke: var(--color-silver);
    stroke-width: 2px;
    fill: var(--color-progress-bar-bg);

    &.active {
      stroke: var(--color-link);
      fill: var(--color-link);
    }
  }
}

.preview-contract-link svg {
  display: none;
}

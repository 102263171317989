.links {
  margin: 5px 0 0 0;

  li {
    display: flex;
    align-items: center;
  }

  a {
    text-transform: capitalize;
  }
}

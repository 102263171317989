.info {
  display: flex;
  align-items: center;
  padding: 0 0 20px 0;
}

.error,
.warning {
  padding: 0 0 20px 0;
}

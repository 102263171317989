.spinner-motion {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    overflow: visible;
  }

  span {
    font-weight: 500;
    color: var(--color-link);
  }
}

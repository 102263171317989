@import "../../ui/media.scss";

.persistent-overlay {
  // Prevent tabbing
  visibility: hidden;

  &.is-active {
    visibility: visible;
  }
}

.dialog-wrapper {
  pointer-events: none;
  transition: opacity 300ms ease;
  opacity: 0;
  position: relative;
  z-index: var(--z-dialog);

  dialog {
    margin: 0;
    padding: 0;
  }

  &.is-active {
    pointer-events: all;
    opacity: 1;
  }

  &.has-error {
    .overlay {
      border: 2px solid var(--color-delete-text);
    }

    strong:first-child {
      color: var(--color-delete-text);
    }
  }

  .overlay {
    z-index: var(--z-dialog);
    background-color: white;
    position: relative;
    border: 2px solid var(--color-link);
    border-radius: 6px;
    min-height: 100px;
    box-shadow: var(--color-button-shadow);
    flex: 1;
    width: 100%;
    margin: 18px;

    @include landscape {
      max-width: 430px;
      margin: 50px;
      width: 100%;
    }

    @include tablet {
      max-width: 600px;
      margin: 50px 24px;
      width: 100%;
    }
  }

  .overlay-body {
    max-height: calc(100vh - 90px);
    overflow-y: auto;
    padding: 20px 16px 20px 16px;

    @include landscape {
      padding: 20px 25px 20px 25px;
    }

    @include tablet {
      max-height: calc(100vh - 100px);
    }

    .status-boxes {
      margin: 5px 0 0 8px;
    }
  }

  .overlay-close {
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: var(--color-bg);

    @include landscape {
      right: -17px;
      top: -17px;
    }
  }

  &.disable-close {
    .overlay-close {
      display: none;
    }
  }
}

#overlay-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: var(--z-dialog-backdrop);
  display: flex;
  justify-content: center;
}

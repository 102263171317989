@use "sass:math";
@import "../../../../../../ui/media.scss";

$grid-gap: 8px;
$section-gap: 24px;
$content-padding-desktop: 18px;
$content-padding-mobile: 12px;
$border-radius: 5px;

@include tablet {
  $grid-gap: 60px;
}

.ecomSelection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 8px;

  @include tablet {
    gap: 40px;
  }
}

.ecomCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: $grid-gap;
  padding: $content-padding-desktop;
  border-radius: $border-radius;

  background-color: var(--color-snow);
  color: var(--color-text);
  opacity: 1;
  box-shadow: var(--color-button-shadow);
  border: 1px solid var(--color-link);
  transition: background-color 300ms ease, box-shadow 300ms ease;

  &:hover,
  &:active,
  &:focus {
    box-shadow: var(--color-button-shadow-transparent);
    background-color: var(--link-alt);

    .circle {
      background-color: #bcdedf;
    }
  }

  &.disabled {
    box-shadow: var(--color-button-shadow-transparent);
    background-color: var(--color-snow);
    border: 1px dashed var(--color-slate);
    opacity: 0.7;
  }
}

.ecomCardActive {
  box-shadow: var(--color-button-shadow-transparent);
  background-color: var(--link-alt);
}

.ecomInfo {
  display: flex;
  flex-direction: column;
  gap: math.div($grid-gap, 2);
  text-align: center;
  line-height: normal;
}

.descriptionOfMoto {
  margin-top: $section-gap;
}

.circle {
  padding: 10px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--color-snow-heavy);
  transition: background-color 300ms ease;
}

.circleActive {
  background-color: #bcdedf;
}

@import "../../../ui/media.scss";

.body {
  margin: 100px auto;
  @include spadding;
  max-width: $desktop-plus;

  iframe {
    border: 0;
    width: 100%;
    min-height: 80vh;
    background-color: white;
  }
}

.columns {
  @include tablet {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > div {
      flex: 0 0 calc(33% - 10px);
      max-width: calc(33% - 10px);

      @include tablet {
        flex: 0 0 calc(33% - 25px);
        max-width: calc(33% - 25px);
      }
    }
  }
}

.divider {
  border-top: 1px solid var(--color-ghost);
}

.wrapper {
  max-width: 600px;
  margin: 60px auto 30px auto;
}

@import "../../ui/media.scss";

.new-overlay-wrapper {
  position: relative;
  z-index: var(--z-dialog);

  .overlay-inner {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  dialog {
    margin: 0;
    padding: 0;
  }

  &.has-error {
    .overlay {
      border: 2px solid var(--color-delete-text);
    }

    strong:first-child {
      color: var(--color-delete-text);
    }
  }

  .overlay-inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    margin: 12px;
  }

  .overlay {
    z-index: var(--z-dialog);
    width: 100%;
    background-color: white;
    position: relative;
    top: 20px;
    z-index: var(--z-dialog);
    border: 2px solid var(--color-link);
    border-radius: 6px;
    min-height: 100px;
    box-shadow: var(--color-button-shadow);

    @include tablet {
      top: 50px;
    }
  }

  .overlay-body {
    max-height: calc(100vh - 90px);
    overflow-y: auto;
    padding: 20px 16px 20px 16px;

    @include landscape {
      padding: 20px 25px 20px 25px;
    }

    @include tablet {
      max-height: calc(100vh - 100px);
    }

    .status-boxes {
      margin: 5px 0 0 8px;
    }
  }

  .overlay-close {
    position: absolute;
    right: -15px;
    top: -15px;
    background-color: var(--color-bg);
    z-index: 300;

    @include landscape {
      right: -17px;
      top: -17px;
    }
  }

  &.disable-close {
    .overlay-close {
      display: none;
    }
  }
}

#new-overlay-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: var(--z-dialog-backdrop);
  display: flex;
  align-items: center;
  justify-content: center;
}

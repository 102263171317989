@import "../../../ui/media.scss";

.button-pane-input {
  .row-wrapper {
    @include tablet {
      display: flex !important;
    }
    align-items: flex-start;
  }

  .input-label-wrapper {
    flex: 1;
    align-items: center;
    align-items: flex-start;

    .required-marker {
      margin-top: 2px;
    }

    .input-label-tag {
      height: auto;
      white-space: unset;
    }

    @include tablet {
      margin-right: 12px;
      margin-top: 8px;

      .input-label {
        padding: 0;
      }
    }
  }

  .input-frame {
    flex-shrink: 0;
    margin-left: auto;
    box-shadow: var(--color-button-shadow);
    border-radius: 5px;
    max-width: 100%;

    @include tablet {
      display: flex;
      justify-content: space-between;
    }

    &.length-2,
    &.length-3,
    &.length-4,
    &.length-5 {
      button {
        @include tablet {
          min-width: 0;
          flex: 1 0 0%;
        }
      }
    }

    &.is-disabled {
      box-shadow: var(--color-button-shadow-transparent);

      button {
        color: var(--color-silver);
        border: 2px solid var(--color-silver);
      }
    }

    &.small {
      button {
        height: var(--height-interaction-small-tablet);
      }
    }

    button {
      display: block;
      justify-content: center;
      align-items: center;
      min-width: 0;
      height: var(--height-interaction);
      padding: 0 16px;
      transition: background-color 300ms ease, box-shadow 300ms ease;
      background-color: var(--color-white);
      color: var(--color-button-ghost);
      border: 2px solid var(--color-button-ghost);
      font-weight: 600;
      border-bottom: 0;
      width: 100%;
      min-width: 80px;

      &.active,
      &:hover,
      &:focus,
      &:active {
        background-color: var(--color-link);
        color: var(--color-white);
      }

      & > span {
        width: 100%;
        display: block;
      }

      @include tablet {
        border: 2px solid var(--color-button-ghost);
        border-right: 0;
        display: inline-flex;
        height: var(--height-interaction-tablet);
      }

      &:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border: 2px solid var(--color-button-ghost);

        @include tablet {
          border-top-right-radius: 5px;
          border-bottom-left-radius: 0;
        }
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        @include tablet {
          border-bottom-left-radius: 5px;
          border-top-right-radius: 0;
        }
      }

      &:disabled,
      &[disabled] {
        color: var(--color-silver);
        border: 2px solid var(--color-silver);
        background-color: var(--color-white);
        cursor: not-allowed;

        &.active {
          background-color: var(--color-silver);
          color: var(--color-white);
        }
      }
    }
  }

  &.small {
    @include tablet {
      .input-label-wrapper {
        margin-top: 4px;
      }
    }
    .required-marker {
      margin-top: 2px;
    }
  }
}

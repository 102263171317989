@import "../../ui/media.scss";

.row {
  padding: 7px 0;
  @include full-margin;
  border-top: 1px solid var(--color-snow-heavy);
  line-height: 1.3;

  &:nth-child(odd) {
    background-color: var(--color-snow);
  }

  &:first-child {
    border-top: 1px solid var(--color-black);
  }

  &:last-child {
    border-bottom: 1px solid var(--color-black);
  }

  img {
    position: relative;
    margin: 0 0 0 2px;
    top: -1px;
  }

  &.today {
    background-color: var(--color-done-light);
  }
}

.info {
  margin: 6px 0 4px 0;
}

.top {
  svg {
    margin: 0 5px 0 0;
    position: relative;
    top: 4px;
  }
}

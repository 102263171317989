@import "../../../../ui/media.scss";

.notes {
  max-width: $tablet;
  margin: 0 auto;

  div.toggle {
    margin: 0;
  }
}

.innerNotes {
  position: relative;
  background-color: var(--link-alt);
  padding: 16px 16px 4px 16px;
  border-radius: 10px;
  margin: -10px 0 0 0;
}

.button {
  position: absolute;
  bottom: 4px;
  right: 16px;
  transition: opacity 300ms ease;
  opacity: 0;
  pointer-events: 0;

  &.active {
    pointer-events: all;
    opacity: 1;
  }
}

.store {
  .terminalName {
    text-transform: capitalize;
    display: inline-block;
    width: 220px;
  }
}

.terminalNo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-smoke);
  font-weight: bold;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

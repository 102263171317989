.body {
  padding: 30px 20px 0 20px;
}

.divider {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;

  .text {
    margin: 0;
    font-weight: 500;
    color: var(--color-text-passive);
    flex-basis: max-content;
  }

  .hr {
    margin: 0;
    flex: 1;
    border-width: 1px;
    border-color: var(--color-snow-heavy);
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  column-gap: 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
  font-weight: 500;
}

.beacon {
  display: flex;
  align-items: center;
  gap: 20px;
}

.hint {
  color: var(--color-red-heavy);
}

.hintCompleted {
  color: var(--color-link);
}

.filled {
  font-size: 1.1em;
  font-weight: bold;
  text-align: end;
}

.remaining {
  font-size: 0.9em;
  color: var(--color-text-passive);
}

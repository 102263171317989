.language-select {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;

  .flag {
    margin-right: 10px;
  }

  .icon {
    margin-right: 10px;
    fill: var(--color-link);
  }

  .input.input-dropdown {
    margin: 0;
    --height-interaction-tablet: 32px;
    --height-interaction: 30px;
    flex: 1;
    border: 0;

    select {
      border: 1px solid var(--link-alt);
    }

    svg {
      top: 3px;
      right: 3px;
    }

    .input-messages,
    .input-label-wrapper {
      display: none;
    }
  }
}

@import "../../ui/media.scss";

.or {
  position: relative;

  .or-divider {
    border-top: 1px solid var(--color-progress-border);
    height: 0px;
    text-align: center;
    display: block;
    line-height: 1;
    width: 100%;
    margin: 24px 0;

    @include tablet {
      margin: 34px 0;
    }

    i {
      position: relative;
      background-color: inherit;
      top: -11px;
      padding: 0 12px;
    }
  }

  &.white-bg {
    .or-divider {
      i {
        background-color: var(--color-white);
      }
    }
  }

  &.bg {
    .or-divider {
      i {
        background-color: var(--color-bg);
      }
    }
  }

  &.horizontal {
    @include tablet {
      max-width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      & > .or-child {
        flex: 0 0 42%;
      }

      & > .or-divider {
        flex: 0 0 8%;
        border-top: 0;
        height: inherit;
        border-left: 1px solid var(--color-progress-border);
        margin: 0 0 0 8%;
      }

      i {
        left: -50%;
        padding: 6px 0;
        line-height: 100%;
        margin: -15px 0 0 0;
        top: 50%;
        display: block;
      }
    }
  }
}

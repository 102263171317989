.imessage {
  background-color: #fff;
  border: 1px solid #e5e5ea;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  font-family: "SanFrancisco";
  font-size: 1.5rem;
  margin: 0 auto 1rem;
  max-width: 600px;
  padding: 0.5rem 1.5rem;

  pre {
    border-radius: 1.15rem;
    line-height: 1.5;
    max-width: 75%;
    padding: 0.5rem 0.875rem;
    position: relative;
    word-wrap: break-word;
  }

  pre::before,
  pre::after {
    bottom: -0.1rem;
    content: "";
    height: 1rem;
    position: absolute;
  }

  pre.from-me {
    align-self: flex-end;
    background-color: #248bf5;
    color: #fff;
  }

  pre.from-me::before {
    border-bottom-left-radius: 0.8rem 0.7rem;
    border-right: 1rem solid #248bf5;
    right: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  pre.from-me::after {
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    right: -40px;
    transform: translate(-30px, -2px);
    width: 10px;
  }

  pre[class^="from-"] {
    margin: 0.5rem 0;
    width: fit-content;
  }

  pre.from-me ~ pre.from-me {
    margin: 0.25rem 0 0;
  }

  pre.from-me ~ pre.from-me:not(:last-child) {
    margin: 0.25rem 0 0;
  }

  pre.from-me ~ pre.from-me:last-child {
    margin-bottom: 0.5rem;
  }

  pre.from-them {
    align-items: flex-start;
    background-color: #e5e5ea;
    color: #000;
  }

  pre.from-them:before {
    border-bottom-right-radius: 0.8rem 0.7rem;
    border-left: 1rem solid #e5e5ea;
    left: -0.35rem;
    transform: translate(0, -0.1rem);
  }

  pre.from-them::after {
    background-color: #fff;
    border-bottom-right-radius: 0.5rem;
    left: 20px;
    transform: translate(-30px, -2px);
    width: 10px;
  }

  pre[class^="from-"].emoji {
    background: none;
    font-size: 2.5rem;
  }

  pre[class^="from-"].emoji::before {
    content: none;
  }

  .no-tail::before {
    display: none;
  }

  .margin-b_none {
    margin-bottom: 0 !important;
  }

  .margin-b_one {
    margin-bottom: 1rem !important;
  }

  .margin-t_one {
    margin-top: 1rem !important;
  }

  .language-label {
    margin-bottom: -2px;
    margin-left: 8px;
    font-size: 12px;
  }
}

@import "../../ui/media.scss";

.vertical-menu {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: var(--color-bg);
  z-index: var(--z-vertical-nav);
  transition: transform 300ms ease;
  padding-top: 30px;
  padding-bottom: 20px;
  width: var(--vertical-menu-width);
  transform: translateX(calc(-1 * var(--vertical-menu-width)));
  @include spadding;

  @include tablet {
    width: var(--vertical-menu-width-tablet);
    transform: translateX(calc(-1 * var(--vertical-menu-width-tablet)));
  }

  @include desktop {
    transform: translateX(0);
  }

  .logo {
    width: 82%;

    @include landscape {
      width: 97%;
    }
  }

  ul {
    padding: 20px 0 0 0;

    .nav-link {
      margin: 14px 0 0 0;
    }

    .link {
      display: flex;
    }
  }

  .menu {
    margin: 0 0 0 -16px;

    .menu-link {
      padding: 0 0 0 16px;
    }

    @include landscape {
      margin: 0 0 0 -25px;

      .menu-link {
        padding: 0 0 0 25px;
      }
    }
  }
}

.menu-wrapper.is-open {
  .vertical-menu {
    transform: translateX(0);
  }
}

.app {
  &.has-vertical-menu {
    @include desktop {
      width: calc(100% - var(--vertical-menu-width-tablet));
      margin: 0 0 0 var(--vertical-menu-width-tablet);
    }
  }
}

.confirm-button {
  position: relative;

  .initial {
    position: relative;
    z-index: 4;
    opacity: 1;
    transition: opacity 300ms ease;
  }

  .confirm {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease;
  }

  &.show-confirm {
    .initial {
      opacity: 0;
    }

    .confirm {
      pointer-events: all;
      opacity: 1;
    }
  }
}

.root {
}

.header {
  display: flex;
  padding: 20px;
  gap: 50px;
  color: var(--color-text-passive);
  border-bottom: 1px solid var(--color-snow-heavy);
}

.instructions {
  font-weight: 500;
  font-size: 0.9em;
  line-height: 1.4;
}

.tooltip {
  padding-top: 2px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  column-gap: 20px;
  padding: 30px 20px 0 20px;
}

.footer {
  border-top: 1px solid var(--color-snow-heavy);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-weight: 500;
}

.beacon {
  display: flex;
  align-items: center;
  gap: 20px;
}

.hint {
  color: var(--color-red-heavy);
}

.hintCompleted {
  color: var(--color-link);
}

.filled {
  font-size: 1.1em;
  font-weight: bold;
  text-align: end;
}

.remaining {
  font-size: 0.9em;
  color: var(--color-text-passive);
}

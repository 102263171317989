@import "../../ui/media.scss";

/////////// GENERAL

.root {
  position: relative;
  max-width: 768px;
  margin: 0 auto;
}

.content {
  background-color: var(--color-bg);
  border-radius: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: 16px;

  @include landscape {
    padding: 26px 16px;
  }

  @include tablet {
    padding: 26px;
  }
}

.expand {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
}

/////////// HEADER

.header {
  display: flex;
  gap: 26px;

  .title {
    text-transform: uppercase;
    margin: 0;
  }
}

.titleWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;

  & > h4 {
    @include truncate;
    max-width: 160px;

    @include tablet {
      max-width: 400px;
    }
  }

  &.has-required-information {
    & > h4 {
      max-width: 220px;

      @include tablet {
        max-width: 400px;
      }
    }
  }
}

.index {
  position: absolute;
  top: -6px;
  color: var(--color-ghost);
}

// Perhaps this flag is an already defined component?
// Also, perhaps there already are predefined typography?
.status {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 600;
  align-self: center;
  padding: 5px 12px;
  border-radius: 5px;
  background-color: rgba(246, 234, 236, 1);
  color: #c04647;

  &.has-partial-information {
    background-color: rgba(255, 246, 213, 1);
    color: #e69900;
  }

  &.has-required-information {
    background-color: var(--link-alt);
    color: var(--color-link);
  }
}

.divider {
  flex: 1;
  border-color: var(--color-progress-border);
  border-width: 2px;
}

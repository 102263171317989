@import "../../../../ui/media.scss";

.add-custom-docs {
  position: relative;

  .custom-doc-input {
    @include landscape {
      display: flex;
      justify-content: space-between;
    }

    .input {
      @include landscape {
        flex: 1 0 auto;
        padding: 0 25px 0 0;
        max-width: 540px;
      }
    }

    .button {
      margin: 28px 0 0 0;

      @include landscape {
        flex: 0 0 auto;
      }
    }
  }
}

.sectionTitle {
  padding: 20px;
  font-size: 1.05em;
  margin-bottom: 5px;
  font-weight: 600;
  border-bottom: 1px solid var(--color-snow-heavy);
}

.acquirerForm {
  background-color: var(--color-snow);
  border-radius: 10px;
  border: 1px solid var(--color-snow-heavy);
  margin-bottom: 50px;
}

.dropdown {
  padding: 30px 20px 10px 20px;
}

.prepaymentForm {
  background-color: var(--color-snow);
  border-radius: 10px;
  border: 1px solid var(--color-snow-heavy);

  margin-bottom: 50px;
}

.prepaymentHeader {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 50px;
  // border-bottom: 1px solid var(--color-snow-heavy);
}

.prepaymentDescription {
  font-weight: 500;
  line-height: 1.4;
  color: var(--color-text-passive);
}

.prepaymentTooltip {
  padding-top: 1.5px;
  padding-right: 1.5px;
}

.prepaymentGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  column-gap: 20px;
  row-gap: 10px;
  padding: 40px 20px 0 20px;
  border-radius: 5px;
}

.prepaymentFooter {
  border-top: 1px solid var(--color-snow-heavy);
  padding: 20px;
}

.prepaymentValidity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  gap: 50px;
}

.beacon {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;

  .beaconText {
    color: var(--color-red-heavy);
  }

  .beaconTextComplete {
    color: var(--color-link);
  }
}

.inputStatus {
  flex-basis: max-content;
  display: flex;
  flex-direction: column;
  text-align: end;

  .progress {
    font-weight: 700;
    font-size: 1.1em;
  }

  .remaining {
    color: var(--color-text-passive);
    font-size: 0.9em;
  }
}

.giftcardsInput {
  padding: 30px 20px 10px 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;

  // background-color: var(--color-snow);
  // border-radius: 10px;
  // border: 1px solid var(--color-snow-heavy);

  label {
    width: 100%;
  }
}

.membershipForm {
  background-color: var(--color-snow);
  border-radius: 10px;
  border: 1px solid var(--color-snow-heavy);
  margin-bottom: 50px;
}

.membershipPercentage {
  padding: 20px 20px 0 20px;

  p {
    padding-left: 0px;
    color: var(--color-text-passive);
  }
}

.membershipPeriods {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  column-gap: 20px;
  row-gap: 10px;

  padding: 20px 20px 20px 20px;

  border-radius: 5px;
}

@import "../../../../../ui/media.scss";

.root {
  position: relative;
}

.store-row {
  .store-row-inner {
    position: relative;
    border-radius: 25px;
    border: 1px solid var(--color-progress-border);
    overflow: hidden;
    margin: 0 0 10px 0;

    .store-row-header {
      // border-radius: 25px;
      cursor: pointer;
      background-color: var(--color-white);
      height: 50px;
      padding: 0 8px 0 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .short-info-contact {
        color: var(--color-ghost);
      }

      @include tablet {
        padding: 0 8px 0 4px;
      }

      .store-row-header-short-info {
        flex: 1 0 auto;
        line-height: 1.2;
        min-width: 0;
        max-width: calc(100% - 80px);
        padding: 0 8px;

        @include tablet {
          padding: 0 14px;
        }

        li {
          font-weight: 500;
          @include truncate;
          width: 100%;
          display: block;
          min-width: 0;
        }
      }
    }

    .store-row-body {
      overflow: hidden;

      .store-form {
        margin-bottom: -24px;
        overflow: hidden;
        padding: 26px 10px 16px 10px;
        border-top: 1px solid var(--color-progress-border);
        background-color: var(--color-progress-bar-bg);

        @include landscape {
          padding: 36px 16px 26px 16px;
        }

        @include tablet {
          padding: 36px 25px 26px 25px;
        }
      }
    }
  }
}

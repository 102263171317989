.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 4px;
  height: 50px;
  position: relative;
  border-radius: 4px;
  background-color: var(--color-snow-heavy);
  border: 1px solid var(--color-progress-border);
}

.icon {
  flex: 0 0 42px;
}

.mapLink {
  flex: 0 0 150px;
  text-align: right;
}

.address {
  flex: 1 1 calc(100% - 192px);
  display: flex;
  align-items: center;

  ul {
    padding: 0 0 0 8px;
    line-height: 1.2;
    font-weight: 500;
  }
}

.secondRow {
  color: var(--color-ghost);
  margin: 0 8px 0 0;
}

@import "../../ui/media.scss";

.wrapper {
  margin: 0 0 40px 0;
}

.list {
  margin: 14px 0 0 0;
}

.divider {
  padding: 5px 0 10px 0;
}

.zoomage__image__container {
  .zoomable {
    & img:hover {
      opacity: 0;
      ~ .help-text {
        opacity: 0;
      }
      ~ .text-blocks {
        display: none;
      }
      ~ .click-to-freeze {
        opacity: 1;
      }
    }

    .detected-text {
      position: absolute;
      content: "";
      border: 1px solid red;
      border-radius: 4px;
    }

    img {
      transition: opacity 0.5s;
      display: block;
      max-height: 75vh;
      width: 100%;
      object-fit: cover;
    }

    .help-text,
    .click-to-freeze {
      position: absolute;
      color: white;
      top: 0px;
      font-style: italic;
      right: 0;
      padding: 0 5px;
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.6);
    }
    .click-to-freeze {
      opacity: 0;
    }

    .sticky {
      cursor: zoom-out;
      img,
      .help-text {
        opacity: 0;
      }
      .text-blocks {
        display: none;
      }
      .click-to-freeze {
        opacity: 1;
      }
    }

    background-position: 50% 50%;
    position: relative;
    overflow: hidden;
    cursor: zoom-in;
  }
}

@import "../../../../../ui/media.scss";
@import "../../../../../ui/typography.scss";

.inner {
  border-radius: 25px;
  border: 1px solid var(--color-progress-border);
  margin: 0 0 10px 0;
}

.header {
  cursor: pointer;
  height: 50px;
  padding: 0 8px 0 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .short-info-contact {
    color: var(--color-ghost);
  }

  @include tablet {
    padding: 0 8px 0 4px;
  }
}

.headerInfo {
  flex: 1 0 auto;
  line-height: 1.2;
  min-width: 0;
  max-width: calc(100% - 80px);
  padding: 0 8px;

  display: flex;
  flex-direction: column;

  @include tablet {
    padding: 0 14px;
  }

  @include text-small;

  li {
    font-weight: 500;
    @include truncate;
    width: 100%;
    display: block;
    min-width: 0;
  }
}

.shortLocationAddress {
  color: var(--color-ghost);
}

.form {
  padding: 26px 10px;
  border-top: 1px solid var(--color-progress-border);

  @include landscape {
    padding: 26px;
  }
}

.body {
  height: 0;
  overflow: hidden;

  &.active {
    height: auto;
    overflow: visible;
  }
}

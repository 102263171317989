@import "../../ui/media.scss";

.info {
  padding: 10px 0;

  & > div {
    padding: 16px;
    // border: 1px solid var(--color-blue-heavy);
    background-color: #e8effd;
    border-radius: 6px;
  }
}

.shortcuts {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }
}

.shortcut {
  margin: 0 0 8px 0;

  &:last-child {
    margin: 0 0 18px 0;
  }
}

@import "../../../ui/media.scss";

.page {
  padding: 80px 0;
}

.bottom,
.top {
  border-left: 1px solid;
  border-image: linear-gradient(transparent, var(--color-text)) 1;
  height: 50px;
  margin: 0 0 0 16px;
}

.bottom {
  border-image: linear-gradient(var(--color-text), transparent) 1;
}

.doc {
  border-left: 1px solid var(--color-text);
  margin: 0 0 0 16px;
  padding: 0 0 60px 50px;
  position: relative;
}

.dataIndicator {
  height: 10px;
  width: 25px;
  position: absolute;
  left: 0;
  top: 6px;
  overflow: visible;

  line {
    stroke: var(--color-text);
  }

  circle {
    fill: var(--color-text);
  }
}

.name {
  display: flex;
  margin: 4px 0 -4px 0px;
  align-items: center;
}

.docs {
  @include desktop {
    display: flex;
  }
}

.timeline {
  @include desktop {
    flex: 0 0 calc(100% - 600px);
  }
}

.form {
  @include desktop {
    flex: 0 0 600px;
  }
}

.warning {
  max-width: 600px;
  margin: 0 0 50px 0;
  line-height: 1.5;
}

.tag {
  position: relative;
  display: inline-block;

  div {
    z-index: 1;
    color: white;
    display: inline-block;
    position: relative;
    line-height: 1;
  }

  .bg {
    z-index: 0;
    position: absolute;
    left: -12px;
    top: -2px;
    width: calc(100% + 24px);
    height: calc(100% + 4px);
    background: black;
    border-radius: 15px;
  }
}

.aligned {
  display: inline-block;
  position: relative;
  margin: 0 6px 0 0;
  top: 6px;
}

.confirmed {
  display: block;
  margin: 0 0 14px 0;
}

.confirm {
  margin: 0 0 0 10px;
}

@import "../../ui/media.scss";

.address-with-search {
  .address {
    padding: 12px;
    border-radius: 4px;
    background-color: var(--color-smoke-light);
  }

  .address-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 0 4px;
    height: 50px;
    position: relative;
    transition: background-color 150ms ease;
    border-radius: 4px;
    background-color: var(--color-snow-heavy);
    border: 1px solid var(--color-progress-border);

    .button {
      box-shadow: none;
      border: 0;
      border-radius: 100px;

      .icon {
        width: 16px;
        height: 16px;
      }
    }

    li {
      font-weight: 500;
      @include truncate;
      width: 100%;
      display: block;
      min-width: 0;
    }

    .short-info {
      flex: 1;
      line-height: 1.2;
      min-width: 0;
      padding: 0 8px;

      .short-info-address {
        color: var(--color-ghost);
      }
    }
  }

  .error-messages {
    display: flex;
    flex-wrap: wrap;
  }

  .address-search-wrapper {
    display: flex;
    flex-direction: column;
    column-gap: 12px;
    margin-bottom: 12px;
    position: relative;

    @include tablet {
      flex-direction: row;
      align-items: center;
      margin-bottom: -10px;
    }

    .search {
      margin-bottom: -24px;

      @include tablet {
        min-width: 70%;
        flex: 1;
        margin-bottom: -12px;
      }
    }
  }
}

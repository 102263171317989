.image {
  background-color: var(--color-bg);
  position: relative;

  & > img {
    opacity: 0;
    transition: opacity 600ms ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.loaded {
    & > img {
      opacity: 1;
    }
  }
}

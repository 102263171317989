@import "../../../../ui/media.scss";

.associate-trace-view {
  li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -16px;
    padding: 0 16px;
    line-height: 1.4;

    @include tablet {
      margin: 0 -25px;
      padding: 4px 25px;
    }

    &:nth-child(odd) {
      background-color: var(--color-snow);
    }

    &:first-child {
      border-top: 1px solid var(--color-input-border);
    }

    .flag {
      position: relative;
      top: -2px;
    }
  }

  .trace-log-icon {
    flex: 0 0 30px;

    svg {
      position: relative;
      top: 4px;
    }
  }

  .trace-log-date {
    font-style: italic;
    flex: 0 0 140px;
  }

  .trace-log-recipient {
    text-align: right;
    flex: 0 0 calc(100% - 170px);
  }

  .trace-log-location {
    flex: 0 0 50%;
    padding: 0 0 0 30px;
  }

  .trace-log-ip {
    text-align: right;
    flex: 0 0 50%;
  }
}

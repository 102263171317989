@import "../../ui/media.scss";

.associate-reminder {
  & > strong {
    display: block;
    margin: 0 0 6px 0;
  }

  .reminder-buttons {
    display: flex;
    justify-content: flex-end;
  }

  .reminder-item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 10px 0;

    @include tablet {
      margin: 0 0 20px 0;
    }
  }

  .reminder-item-beacon {
    flex: 0 0 auto;
    width: 40px;
    position: relative;
    top: 2px;
  }

  .reminder-item-date {
    flex: 0 0 100%;

    @include landscape {
      flex: 0 0 150px;
    }
  }

  .reminder-item-text {
    flex: 1 0 calc(100% - 40px);

    @include landscape {
      flex: 0 0 calc(100% - 190px);
    }
  }

  .reminder-item-date {
    font-style: italic;
    text-align: right;

    & > span {
      opacity: 0.7;
    }
  }
}

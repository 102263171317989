@use "sass:math";
@import "../../../../ui/typography.scss";
@import "../../../../ui/media.scss";

$grid-gap: 12px;
$section-gap: 24px;
$content-padding-desktop: 18px;
$content-padding-mobile: 12px;
$border-radius: 5px;

@mixin inactiveCardColors {
  background-color: var(--color-snow);
  color: var(--color-silver);
  opacity: 1;
}

@mixin activeCardColors {
  opacity: 1;
  background-color: var(--link-alt);
  color: var(--color-text);
  border: 1px solid var(--color-link);
  font-weight: 500;
}

////////// GENERAL

.content {
  display: flex;
  flex-direction: column;
  gap: $section-gap;
}

.title {
  margin-bottom: $grid-gap;
  // color: var(--color-text-passive);
  font-weight: 500;
}

.sectionTitle {
  margin-bottom: 8px;
  font-weight: bold;
}

////////// PRODUCTS

.products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 60px;
  gap: $grid-gap;
}

.productCard {
  display: flex;
  align-items: center;
  gap: $grid-gap;

  border-radius: $border-radius;
  padding: 8px;

  @include inactiveCardColors();
}

.productCardActive {
  @include activeCardColors();
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--color-bg);
  fill: var(--color-silver);
  height: 38px;
  width: 38px;
  border-radius: 50%;
}

.circleActive {
  background-color: var(--color-link);
  fill: var(--color-bg);
  color: var(--color-bg);
}

.productInfo {
  display: flex;
  flex-direction: column;
  line-height: normal;
  gap: 4px;
}

.productName {
  text-transform: capitalize;
}

.productDescription {
  @include text-small;
}

/////////// TERMINALS

.terminals {
  border-radius: $border-radius;
  padding: $content-padding-mobile;
  background-color: var(--color-snow);

  @include tablet {
    padding: $content-padding-desktop;
  }

  & > *:not(:first-child) {
    padding-top: $grid-gap;
    margin-top: $grid-gap;

    @include tablet {
      padding-top: $grid-gap * 1.5;
      margin-top: $grid-gap * 1.5;
    }
    border-top: 1px solid var(--color-snow-heavy);
  }
}

.terminalProducts {
  display: flex;
  flex-wrap: wrap;
  gap: $grid-gap;

  & > * {
    flex: 1 1 45%;
    margin-bottom: 10px;
  }
}

.secondaryText {
  color: var(--color-text-passive);
}

.emptyText {
  color: var(--color-text-passive);
}

.variable-list {
  dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  dt,
  dd {
    display: inline;
    margin: 0;
    padding: 0;
  }

  code {
    background-color: var(--color-smoke);
    padding: 4px 6px;
    font-size: 14px;
  }

  .variable-icon-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 50%;
    box-shadow: var(--color-button-shadow);
    background-color: var(--color-button);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-button-hovered);
    }
  }
}

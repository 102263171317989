@import "../../../../../ui/media.scss";

.terminal {
  margin: 2px -10px 0 -10px;
  padding: 10px;

  @include landscape {
    margin: 2px -16px 0 -16px;
    padding: 14px 16px;
  }

  @include tablet {
    margin: 5px -26px 0 -26px;
    padding: 16px 26px;
  }
}

.background {
  background-color: transparent;
  transition: background-color 300ms ease, border-color 300ms ease;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &.hasTerminals {
    background-color: var(--color-progress-bar-bg);
    border-color: var(--color-progress-border);
  }
}

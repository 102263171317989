@import "../../../../../ui/media.scss";

.confirm-bank {
  padding: 0 0 20px 0;
  max-width: $desktop;

  dl {
    display: flex;
    max-width: $landscape;
    flex-wrap: wrap;
  }

  dt,
  dd {
    margin: 0;
  }

  dt {
    width: 0;
    flex: 0 0 140px;
    font-style: italic;
  }

  dd {
    width: 0;
    flex: 1 0 calc(100% - 140px);
    @include truncate;
  }

  .confirm-bank-statuses {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 16px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms ease;
    font-weight: 500;
    background: linear-gradient(
      rgba(255, 255, 255, 0.85) 50%,
      rgba(255, 255, 255, 0.4)
    );
  }

  &.show-status {
    .confirm-bank-statuses {
      opacity: 1;
    }
  }
}

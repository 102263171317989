.collapsible {
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(
      to bottom,
      transparent 0%,
      var(--color-progress-bar-bg) 75%
    );
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s;
  }

  &.is-collapsed:after {
    opacity: 1;
  }
}

@import "../../ui/media.scss";

.box {
  background-color: var(--link-alt);
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid var(--color-link);

  @include tablet {
    padding: 16px 25px;
  }

  hr {
    border-top: 1px solid var(--color-link);
  }
}

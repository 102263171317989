@import "../../../ui/media.scss";

.completed-contracts-page {
  background-color: var(--color-progress-bar-bg);
  padding: 10px 0 100px 0;

  .status-boxes {
    margin: 10px 0;
  }

  dl {
    @include desktop {
      display: flex;
      flex-wrap: wrap;
    }
  }

  dt {
    font-style: italic;

    @include desktop {
      flex: 0 0 165px;
    }
  }

  dd {
    line-height: 1.4;
    margin: 0 0 24px 0;

    @include desktop {
      flex: 0 0 auto;
      width: calc(100% - 165px);
    }
  }

  article .button {
    margin: 0 10px 10px 0;
    width: 100%;

    @include landscape {
      width: auto;
      margin: 0 10px 0 0;
    }
  }

  .copy-link {
    position: fixed;
    bottom: 30px;
  }
}

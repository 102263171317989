@import "../../ui/media.scss";

.radio {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;

  @include tablet {
    height: 24px;
    width: 24px;
  }

  .radio-checked,
  .radio-unchecked {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 300ms ease;
    transform-origin: center;
    stroke: var(--color-link);
    fill: var(--color-bg);
  }

  .radio-checked {
    transform: scale(0);
    stroke-width: 3px;
    fill: var(--color-link);
    stroke: var(--color-bg);
  }

  &.checked {
    .radio-checked {
      transform: scale(1);
    }

    .radio-unchecked {
      transform: scale(0);
    }
  }
}

.radio-buttons {
  &.is-disabled {
    .radio-unchecked {
      stroke: var(--color-progress-border);
    }

    .radio-checked {
      fill: var(--color-progress-border);
    }
  }
}

@import "../../ui/media.scss";

.table-wrapper {
  overflow-x: scroll;
  border: 1px solid var(--color-link);
  border-radius: 5px;
  box-shadow: 0 6px 14px -2px rgba(0, 0, 0, 0.1);
  background-color: var(--color-link);

  @include desktop {
    overflow-x: auto;
  }

  table {
    min-width: 100%;
  }

  thead {
    th {
      font-weight: 600;
      text-align: left;
      white-space: nowrap;
      background-color: var(--color-link);
      color: var(--color-white);

      &:first-child {
        border-top-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
      }
    }

    button {
      color: var(--color-white);
      font-weight: 600;
      display: inline-block;
      width: 100%;
      text-align: left;
      position: relative;
    }
  }

  .ascending:after,
  .descending:after {
    position: absolute;
    content: " ";
    border: 6px solid transparent;
    margin: 0 0 0 8px;
  }

  .ascending:after {
    top: 10px;
    border-top-color: var(--color-white);
  }

  .descending:after {
    top: 2px;
    border-bottom-color: var(--color-white);
  }

  .ascending,
  .descending {
    padding-right: 10px;
  }

  tr:nth-child(even) {
    td {
      background-color: var(--link-alt);
    }
  }

  .truncate {
    @include truncate;
  }

  .beacon {
    display: inline-block;
  }

  th,
  td {
    text-align: left;
    max-width: 320px;

    &.text-right {
      text-align: right;
    }

    &.text-center {
      text-align: center;
    }

    &.vertical-center {
      vertical-align: middle;
    }
  }

  th {
    padding: 0 10px;
    min-height: 40px;
    line-height: 40px;
  }

  td {
    padding: 4px 10px;
    background-color: var(--color-bg);
  }
}

@import "../../../ui/media.scss";

.page {
  padding: 50px 0;
}

.card {
  margin: 40px 0 0 0;
  padding: 0;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.5) 0px 3px 34px 0px;

  ul {
    max-width: 100%;
    overflow: scroll;
  }
}

.list {
  width: 1440px;

  li {
    display: flex;
    align-items: center;
  }
}

.striped {
  background-color: var(--color-snow);

  & > div {
    background-color: var(--color-snow);
  }
}

.padded {
  @include spadding;
}

.top {
  @include spadding;
  margin: 0 0 20px 0;

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  li {
    margin: 0 20px 0 0;
  }
}

.tag {
  line-height: 1;
  padding: 4px 6px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.2rem;
  border-radius: 8px;
  display: inline-block;
}

.sales {
  background-color: #0e7eb5;
  color: white;
}

.risk {
  background-color: #eecc0f;
}

.merchant {
  background-color: #8034d1;
  color: white;
}

.system {
  background-color: var(--color-text);
  color: white;
}

.header-indicator-cell,
.indicator-cell {
  flex: 0 0 6px;
  position: relative;
}

.header-flag,
.flag {
  flex: 0 0 80px;
}

.header-flag {
  padding: 0 0 0 8px;
}

.header-created,
.created {
  flex: 0 0 90px;
}

.header-phase,
.phase {
  flex: 0 0 140px;
}

.header-status,
.status {
  flex: 0 0 194px;
}

.status {
  @include truncate;
}

.header-name,
.name {
  @include truncate;
  flex: 0 0 200px;
}

.header-sf,
.header-contractId,
.sf,
.contractId {
  flex: 0 0 50px;
}

.sf,
.contractId {
  text-align: center;

  :global(a.as-button.ghost .icon) {
    fill: none;
    stroke: var(--color-link);
  }
}

.header-legalEntityType,
.legalEntityType {
  @include truncate;
  flex: 0 0 180px;
}

.header-productType,
.productType {
  @include truncate;
  flex: 0 0 150px;
}

.header-user,
.user {
  @include truncate;
  flex: 0 0 200px;
  text-align: end;
}

.header-orgno,
.orgno {
  @include truncate;
  flex: 0 0 100px;
  text-align: end;
}

.header-user {
  :global(.button) {
    justify-content: flex-end;
  }
}

.indicator-cell,
.flag,
.created,
.phase,
.status,
.name,
.sf,
.contractId,
.legalEntityType,
.productType,
.orgno,
.user {
  height: 48px;
  padding-top: 10px;

  button {
    justify-content: flex-start;
  }
}

.headers {
  font-weight: 600;
  background-color: var(--color-text);
}

.header-contractId,
.header-sf {
  background-color: var(--color-text);
}

.danger {
  & > div {
    border-left: 6px solid var(--color-red);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.warning {
  border-left: 6px solid var(--color-yellow-heavy);
}

.success {
  border-left: 6px solid var(--color-green-heavy);
}

.indicators {
  margin: 20px 0 40px 0;

  span {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin: 0 5px 0 0;
    position: relative;
    top: 1px;
  }
}

.dangerIndicator {
  background-color: var(--color-red);
}

.warningIndicator {
  background-color: var(--color-yellow-heavy);
}

.ongoingIndicator {
  background-color: var(--color-green-heavy);
}
